import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "E-mail": "E-mail",
      "Accept": "Accept",
      "Clients": "Clients",
    }
  },
  ru: {
    translation: {
      "E-mail": "E-mail",
      "Accept": "Принять",
      "Clients": "Клиенты",
    }
  }
};

i18n
  .use(initReactI18next) //Clients personal space passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'ru',
    lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
