import {cloneElement, useState} from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layout = ({children}) => {

  return (
    <div className="layout">
      {cloneElement(children)}
    </div>
  )
}

export default Layout;
