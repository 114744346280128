import { useState, useEffect, useCallback } from "react";

export const useAudio = (url) => {
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState(false);

  const toggle = useCallback(() => setPlaying(prevPlaying => !prevPlaying), []);

  useEffect(() => {
    const newAudio = new Audio(url);
    newAudio.volume = 0.3;
    newAudio.loop = true;
    setAudio(newAudio);

    const handleEnded = () => setPlaying(false);

    newAudio.addEventListener('ended', handleEnded);

    return () => {
      newAudio.removeEventListener('ended', handleEnded);
      newAudio.pause();
      newAudio.src = '';
    };
  }, [url]);

  useEffect(() => {
    if (audio) {
      if (playing) {
        audio.play().catch(error => {
          console.error("Error playing audio", error);
        });
      } else {
        audio.pause();
      }
    }
  }, [audio, playing]);

  return [playing, toggle];
};
