import {useEffect, useState} from "react";
import logo from "../../assets/img/icons/logo.svg";
import soundIcon from "../../assets/img/svg.svg";
import audio from "../../assets/audio/intro_web.mp3";
import {useAudio} from "../../Hooks/useAudio";
import "./Home.css";

const Home = () => {
  const [playing, toggle] = useAudio(audio);
  const [rotation, setRotation] = useState(0);
  const [lastTime, setLastTime] = useState(null);

  useEffect(() => {
    let animationFrameId;

    if (playing) {
      const updateRotation = (currentTime) => {
        if (lastTime !== null) {
          const delta = currentTime - lastTime;
          setRotation(prevRotation => prevRotation + (delta * 0.032));
        }
        setLastTime(currentTime);
        animationFrameId = requestAnimationFrame(updateRotation);
      };

      animationFrameId = requestAnimationFrame(updateRotation);
    } else {
      setLastTime(null);
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [playing, lastTime]);

  return (
    <div className='home'>
      <div className={!playing ? "glow-container" : "glow-container spin"}>
        <img
          className="home_logo"
          style={{ transform: `rotate(${rotation}deg)` }}
          src={logo}
          alt="Umbral Eclipse"
          onClick={toggle}
        />
        <img src={soundIcon} alt="Sound" className={!playing && "soundIcon"}/>
      </div>
      <h1 className='home_header'>
        Umbral Eclipse
      </h1>
      <span className='home_descr'>
        Where Cosmic Echoes Meet Earthly Rhythms
      </span>
      <a href="https://t.me/umbraleclipse" className='home_btn' target="__blank">
        Join the journey
      </a>
    </div>
  )
}

export default Home;
